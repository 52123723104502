<template>
  <div class="content">
    <div class="main-screen-section inside screen5"></div>

    <div class="container inside-page content-section">
      <h1>Жизнь с мигренью</h1>
      <div class="content-text text-size-big mb20">
        <p>
          Мигрень – это хроническое заболевание, приводящее к физической
          <br class="hidden-xs" />
          и психологической нетрудоспособности, которое нарушает способность
          человека выполнять повседневную деятельность и тем самым оказывает
          отрицательное влияние на качество жизни пациента.
        </p>
      </div>

      <div class="migrain-perc">
        <div class="row">
          <div class="col-md-4">
            <div class="m-perc-block">
              <div class="num">92%</div>
              <div class="text">
                Опрошенных пациентов <br class="hidden-xs" />
                с мигренью уверены, что это <br class="hidden-xs" />
                заболевание отрицательно влияет <br class="hidden-xs" />
                на общее качество их жизни
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="m-perc-block">
              <div class="num">69%</div>
              <div class="text">
                Утверждают, что мигрень повлияла <br />
                на их социальную жизнь
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="m-perc-block">
              <div class="num">56%</div>
              <div class="text">
                Утверждают, что мигрень повлияла <br />
                на работу/карьеру
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-text text-size-middle">
        <h3>Влияние на разные аспекты жизни</h3>
        <p>
          Результаты исследования с участием 12 545 пациентов с мигренью,
          проведенного в 16 странах, показали, что люди, страдающие от мигрени,
          вынуждены справляться не только с физическими симптомами болезни, но и
          с воздействием, которое это состояние оказывает на многие аспекты их
          повседневной жизни.
        </p>
      </div>

      <div class="life-mig">
        <div class="sub-text">
          «Ценю каждый<br />
          момент жизни <br />без мигрени»
        </div>
        <img src="img/pic-life.jpg" class="img-responsive" alt="img"/>
      </div>

      <div class="content-text text-size-middle">
        <ul class="list-block mb40">
          <li>
            <p>
              Мигрень приводит к существенному снижению функциональности во
              время и между приступами.
            </p>
          </li>
          <li>
            <p>
              &lt;43% пациентов с мигренью утверждают, что полностью
              восстанавливаются в период между приступами.
            </p>
          </li>
          <li>
            <p>
              У пациентов с мигренью существенно понижено связанное со здоровьем
              качество жизни (СЗКЖ), чем в <br />
              общей популяции без хронических болезней.
            </p>
          </li>
          <li>
            <p>По мере повышения нетрудоспособности снижается СЗКЖ.</p>
          </li>
          <li>
            <p>
              У пациентов с мигренью значительно больше физических симптомов
              (конверсия, сердечно-легочные, <br />
              боль, ЖКТ, сексуальные и пр.) и выше частота психических
              расстройств, чем у пациентов без мигрени.
            </p>
          </li>
        </ul>

        <h3 class="mb30">Влияние мигрени в графике</h3>

        <div class="graph">
          <img src="img/graph.png" alt="img"/>
          <div class="influence">
            <div class="title">Влияние мигрени</div>
            <div class="text type1">Очень отрицательное</div>
            <div class="text type2">Довольно отрицательное</div>
            <div class="text type3">Немного отрицательное</div>
          </div>
        </div>

        <div class="patients-row">
          <div class="row">
            <!-- <div class="col-md-6">
              <a href="#" class="patient-block">
                <div class="pic" style="background-image: url('img/pic6.jpg')"></div>
                <div class="desc type2">
                  <div class="title">Программа поддержки пациентов</div>
                  <div class="button"><span>Подробнее</span></div>
                </div>
              </a>
            </div> -->
            <div class="col-md-6">
              <a
                href="https://migrainehelp.ru/stories/all"
                target="_blank"
                class="patient-block"
              >
                <div
                  class="pic"
                  style="background-image: url('img/pic7.jpg')"
                ></div>
                <div class="desc type2">
                  <div class="title">Истории пациентов</div>
                  <div class="button"><span>Подробнее</span></div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LivingWithMigraines',
  metaInfo: {
    title: 'Жизнь с мигренью',
    meta: [
      { name: 'description', content: 'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность' }
    ]
  }
}
</script>
